import React, { useRef } from 'react';

const SidebarStateContext = React.createContext();
const SidebarDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useSidebarState() {
  const context = React.useContext(SidebarStateContext);
  if(!context) throw new Error('useSidebarState must be used in SidebarProvider');
  return context;
}

function useSidebarDispatch() {
  const context = React.useContext(SidebarDispatchContext);
  if(!context) throw new Error('useSidebarDispatch must be used in SidebarProvider');
  return context;
}

function SidebarProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { 
    open: true, 
    buckets: [],
    bucketsRef: useRef(null),
  });

  return (
    <SidebarStateContext.Provider value={state}>
      <SidebarDispatchContext.Provider value={dispatch}>
        {props.children}
      </SidebarDispatchContext.Provider>
    </SidebarStateContext.Provider>
  )
}

function stateToggleSidebar(dispatch, newState) {
  dispatch(newState);
}

function stateUpdateSidebar(dispatch, { buckets }) {
  dispatch({ buckets });
}

export { SidebarProvider, stateToggleSidebar, stateUpdateSidebar, useSidebarDispatch, useSidebarState };