import React from 'react';

const SandboxStateContext = React.createContext();
const SandboxDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useSandboxState() {
  const context = React.useContext(SandboxStateContext);
  if(!context) throw new Error('useSandboxState must be used in SandboxProvider');
  return context;
}

function useSandboxDispatch() {
  const context = React.useContext(SandboxDispatchContext);
  if(!context) throw new Error('useSandboxDispatch must be used in SandboxProvider');
  return context;
}

function SandboxProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { sandbox: [] });

  return (
    <SandboxStateContext.Provider value={state}>
      <SandboxDispatchContext.Provider value={dispatch}>
        {props.children}
      </SandboxDispatchContext.Provider>
    </SandboxStateContext.Provider>
  )
}

function updateSandbox(dispatch, { sandbox }) {
  dispatch({ sandbox });
}

export { SandboxProvider, updateSandbox, useSandboxDispatch, useSandboxState };