import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datepicker/dist/react-datepicker.css'
import './assets/css/main.css'
import './assets/css/main-override.css'
import './assets/css/color-override.css'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { TenantProvider } from 'contexts/TenantContext'
import Page from './pages/Page'
import Landing from './pages/Landing'
import ForgotPassword from './pages/account/ForgotPassword'
import Login from './pages/account/Login'
import ResetPassword from './pages/account/ResetPassword'
import Authenticated from './layouts/Authenticated'
import Global from './layouts/Global'
import Unauthenticated from './layouts/Unauthenticated'
import AllProjects from 'pages/dashboards/AllProjects'
import AllProjectsWrapper from './pages/dashboards/AllProjects/Wrapper'
import DynamicBoard from './pages/dashboards/DynamicBoard'
import DynamicBoardWrapper from './pages/dashboards/components/DynamicBoard/Wrapper'
import StaticBoardWrapper from './pages/dashboards/components/StaticBoard/Wrapper'
import TaskModalWrapper from './pages/dashboards/components/TaskModal/Wrapper'
import ModalNewTask from 'pages/dashboards/modals/NewTask'
import StaticBoard from './pages/dashboards/StaticBoard'
// import TaskModal from './pages/dashboards/TaskModal'
// import ModalTask from './pages/dashboards/TaskModal'
import ModalTask from './pages/dashboards/modals/Task'
import SmsHistory from 'pages/sms/History'
import TopUp from 'pages/sms/TopUp'
import SmsHistoryModal from 'pages/sms/History/Modal'
import TopUpConfirmationModal from 'pages/sms/TopUp/Confirmation/Modal'
import TopUpHistoryModal from 'pages/sms/TopUp/History/Modal'
import Archive from 'pages/archive'
import EmailVerify from 'pages/account/EmailVerify'
import MyDetails from 'pages/account/MyDetails'
import CreateUser from 'pages/user/Create'
import EditUser from 'pages/user/Edit'
import BusinessDetails from 'pages/settings/BusinessDetails'
import DashboardContextSandbox from 'pages/sandboxes/DashboardContextSandbox/Sandbox'
import DashboardContextSandboxWrapper from 'pages/sandboxes/DashboardContextSandbox/SandboxWrapper'
import UseEffectPropertySandbox from 'pages/sandboxes/UseEffectPropertySandbox/Sandbox'
import UseEffectPropertySandboxWrapper from 'pages/sandboxes/UseEffectPropertySandbox/SandboxWrapper'

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Global />}>
          <Route path="account" element={<Unauthenticated />}>
            <Route path="password/forgot" element={<ForgotPassword />} />
            <Route path="password/reset/:token/:email" element={<ResetPassword />} />
            <Route path="login" element={<Login />} />
          </Route>

          <Route path="/" element={<Authenticated />}>
            <Route path="/" element={<Page><Landing /></Page>} />
            
            <Route path="account">
              <Route path="email/verify" element={<Page><EmailVerify /></Page>} />
              <Route path="my-details" element={<Page><MyDetails /></Page>} />
            </Route>
            
            <Route path="user">
              <Route path="create" element={<Page><CreateUser /></Page>} />
              <Route path="edit" element={<Page><EditUser /></Page>} />
            </Route>

            <Route path="projects">
              <Route path="all?"
                // element={<Page><DynamicBoardWrapper><DynamicBoard /></DynamicBoardWrapper></Page>} />
                element={<Page><AllProjectsWrapper><AllProjects /></AllProjectsWrapper></Page>} />
              <Route path="new-task/:project?"
                // element={<Page><DynamicBoardWrapper><DynamicBoard /><ModalNewTask /></DynamicBoardWrapper></Page>} />
                element={<Page><AllProjectsWrapper><AllProjects /><ModalNewTask /></AllProjectsWrapper></Page>} />
              <Route path="archive"
                element={<Page><Archive /></Page>} />
              <Route path="archive/task/:taskId"
                element={<Page><Archive /><TaskModalWrapper><ModalTask archive={true} /></TaskModalWrapper></Page>} />{/* TODO: Remove archive prop */}
              <Route path=":project"
                element={<Page><StaticBoardWrapper><StaticBoard /></StaticBoardWrapper></Page>} />
              <Route path="task/:taskId"
                // element={<Page><DynamicBoardWrapper><DynamicBoard /><TaskModalWrapper><ModalTask /></TaskModalWrapper></DynamicBoardWrapper></Page>} />
                element={<Page><AllProjectsWrapper><AllProjects /><TaskModalWrapper><ModalTask /></TaskModalWrapper></AllProjectsWrapper></Page>} />
              <Route path=":project/task/:taskId"
                element={<Page><StaticBoardWrapper><StaticBoard /><TaskModalWrapper><ModalTask /></TaskModalWrapper></StaticBoardWrapper></Page>} />
            </Route>

            <Route path="sandbox">  {/* TODO: remove sandboxes. */}
              <Route path="dashboard-context" element={
                <Page>
                  <DashboardContextSandboxWrapper>
                    <DashboardContextSandbox />
                  </DashboardContextSandboxWrapper>
                </Page>
              } />
              <Route path="use-effect-property-context" element={
                <Page>
                  <UseEffectPropertySandboxWrapper>
                    <UseEffectPropertySandbox />
                  </UseEffectPropertySandboxWrapper>
                </Page>
              } />
            </Route>
            
            <Route path="sms">
              <Route path="history" element={<Page><SmsHistory /></Page>} />
              <Route path="history/:sid" element={<Page><SmsHistory /><SmsHistoryModal /></Page>} />
              <Route path="top-up" element={<Page><TopUp /></Page>} />
              <Route path="top-up/history/:transactionId" element={<Page><TopUp /><TopUpHistoryModal /></Page>} />
              <Route path="top-up/confirmation" element={<Page><TopUp /><TopUpConfirmationModal /></Page>} />
            </Route>

            <Route path="settings">
              <Route path="business-details" element={<Page><BusinessDetails /></Page>} />
            </Route>
          </Route>
        </Route>
      </>
    )
  );

  return (
    <TenantProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </TenantProvider>
  )
}
export default App;
