import SB from 'assets/js/classes/SubmitBtn'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import extender from 'assets/js/utilities/extender'
import handleApiCall from 'assets/js/utilities/handleApiCall'
import collectFormData from '../utilities/collectFormData'

class Buckets {
  static async handleCreateBucket(e, modelContext, formControlContext, toastsContext) {
    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    // API CALL
    const response = await handleApiCall({
      apiMethod: AxiosMethods.POST, 
      apiRoute: ApiRoutes.BUCKETS,
      callback: ({ bucket }) => {
        [modelContext.dashboard, modelContext.searchBar].forEach(({ dataset, dispatch, prop, update }) => {
          dataset.splice(0, 0, bucket);
          update(dispatch, { [prop]: dataset });
        });

        // FIRE SYNTHETIC EVENT
        e.target.dispatchEvent(new Event(`${AxiosMethods.POST}::${ApiRoutes.BUCKETS}`));
      },
      formData: { title: "New List/Dashboard" },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // RESTORE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }

  static async handleDeleteBucket(e, modelContext, formControlContext, toastsContext) {
    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    // API CALL
    const response = await handleApiCall({
      apiMethod: AxiosMethods.DELETE, 
      apiRoute: ApiRoutes.BUCKET(modelContext.bucket.id),
      callback: () => {
        [modelContext.dashboard, modelContext.searchBar].forEach(({ dataset, dispatch, prop, update }) => {
          dataset.splice(dataset.findIndex((bucket) => bucket.id === modelContext.bucket.id), 1);
          update(dispatch, { [prop]: dataset });
        });
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // RESTORE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }

  static async handleUpdateBucket(e, modelContext, formControlContext, toastsContext) {
    const form = e.currentTarget?.form ?? e.currentTarget; // Need to get form before changing button state because currentTarget won't be available after elements in button change.
    
    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { disabled: true });

    // API CALL
    const response = await handleApiCall({
      apiMethod: AxiosMethods.PATCH,
      apiRoute: ApiRoutes.BUCKET(modelContext.bucket.id),
      callback: ({ bucket }) => {
        [modelContext.dashboard, modelContext.searchBar].forEach(({ dataset, dispatch, prop, update }) => {
          const idx = dataset.findIndex((bucket) => bucket.id === modelContext.bucket.id);
          dataset[idx] = { ...bucket, tasks: dataset[idx].tasks };
          update(dispatch, { [prop]: dataset });
        });
      },
      formData: collectFormData(form),
      ...toastsContext
    });

    // RESTORE INPUT
    await formControlContext.update(formControlContext.dispatch, { disabled: false });

    return response;
  }
}

class Events {
  static eventGetTaskIndex(task, buckets) {
    const bucketIdx = buckets.findIndex(bucket => bucket.id === task.bucket_id);
    const taskIdx = buckets.at(bucketIdx).tasks.data.findIndex(_task => _task.id === task.id);
    return { bucketIdx, taskIdx };
  }

  static eventBucketUpdated(bucket, buckets) {
    const bucketIdx = buckets.findIndex(_bucket => _bucket.id === bucket.id);
    
    if ([bucketIdx].find(el => el === -1)) return buckets;
    
    buckets[bucketIdx] = { ...bucket, tasks: { ...buckets[bucketIdx].tasks, data: buckets[bucketIdx].tasks.data } };
    return buckets.sort((a, b) => a.order_index > b.order_index ? 1 : -1);
  }

  static eventTaskUpdated(task, buckets) {
    const { bucketIdx, taskIdx } = Events.eventGetTaskIndex(task, buckets);
    
    if ([bucketIdx, taskIdx].find(el => el === -1)) return buckets;
    
    buckets.at(bucketIdx).tasks.data[taskIdx] = task;
    return buckets;
  }
}

class UI {
  static handleKeyDown(e, inputRef, oldVal, setState, modelContext, formControlContext, toastsContext) {
    if (e.key === 'Enter') {
      UI.handleInputEnter(e, inputRef, oldVal, modelContext, formControlContext, toastsContext);
    } else if (e.key === 'Escape') {
      UI.handleInputEscape(e, inputRef, oldVal, setState);
    }
  }

  static async handleInputEnter(e, inputRef, oldVal, modelContext, formControlContext, toastsContext) {
    if (e.key === 'Enter') {
      if (oldVal === inputRef.current?.value) {  // Value did not change, don't update state
        inputRef.current?.blur();
      } else { 
        await Buckets.handleUpdateBucket(e, modelContext, formControlContext, toastsContext);
        inputRef.current?.focus();  // Need to focus again for blur event to work
        inputRef.current?.blur();
      }
    }
  }

  static handleInputEscape(e, inputRef, oldVal, setState) {
    if (e.key === 'Escape') {  // Cancel edit
      if (oldVal === inputRef.current?.value) {  // Value did not change, don't update state
        document.activeElement.blur();
      } else {
        setState(oldVal, () => inputRef.current?.blur());
      }
    }
  }
}

export default class Dashboard extends extender([Buckets, Events, UI]) {}