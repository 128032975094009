import { Button, Container } from 'react-bootstrap'
import { SandboxProvider, updateSandbox, useSandboxDispatch, useSandboxState } from './context01'
import { useEffect, useState } from 'react';

export default function UseEffectPropertySandbox() {
  // CONTEXT PARAMS — SANDBOX
  const { sandbox } = useSandboxState();
  const sandboxDispatch = useSandboxDispatch();

  // STATE PARAMS
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0});

  useEffect(() => {
    setRgb({ 
      r: Math.floor(Math.random() * 255),
      g: Math.floor(Math.random() * 255),
      b: Math.floor(Math.random() * 255),
    });
  }, [sandbox.number]);

  return (
    <Container>
      <Button onClick={() => updateSandbox(sandboxDispatch, { sandbox: { number: Math.floor(Math.random() * 100) } })}>
        Change color
      </Button>
      <p
        style={{ background: `rgb(${rgb.r},${rgb.g},${rgb.b})` }} 
      >
        {JSON.stringify(sandbox)}
      </p>
    </Container>
  )
}