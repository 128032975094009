// https://github.com/facebook/react/issues/14010#issuecomment-433788147
import Dashboard from 'assets/js/classes/Dashboard'
import handleToast from './handleToast'

// eventBucketUpdated(data, modelContext, toastsContext)
export default function eventBucketUpdated({ author, user, bucket }, contexts, { toasts, toastsDispatch, updateToasts }) {
  Object.values(contexts).forEach(({ dataset, dispatch, prop, update }) => {
    console.log({ dataset, dispatch, prop, update });
    Dashboard.eventBucketUpdated(bucket, dataset);
    update(dispatch, { [prop]: dataset });
  });
  
  if (author?.id === user?.id) return;  // Don't throw toast.

  handleToast({
    title: 'Dashboard Updated', 
    message: `Dashboard "${bucket?.title}" was updated by ${author?.name} (${author?.email}). The latest version has automatically been loaded for you.`,
    autohide: true, 
  }, { toasts, toastsDispatch, updateToasts });
}