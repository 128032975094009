import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import handleApiCall from 'assets/js/utilities/handleApiCall'

export default class DragDrop {
  static BUCKETS = 'buckets';
  static TASKS = 'tasks';

  constructor(buckets) {
    this.buckets = buckets;
  }

  #getBucketIndex(droppableId) {
    return this.buckets.findIndex(bucket => String(bucket.id) === String(droppableId).match(/\d+/g)[0]);
  }

  #getTaskIndex(draggableId, sourceDroppableId) {
    const sourceBucketIndex = this.#getBucketIndex(sourceDroppableId);
    return this.buckets[sourceBucketIndex].tasks.findIndex(task => String(task.id) === String(draggableId).match(/\d+/g)[0]);
  }

  static #getId(draggableId) {
    return String(draggableId).match(/\d+/g)[0];
  }

  #moveBucket(destination, source) {
    const bucket = this.buckets.splice(source.index, 1).at(0);
    this.buckets.splice(destination.index, 0, bucket);
  }

  #moveTask(destination, source) {
    const sourceBucketIndex = this.#getBucketIndex(source.droppableId);
    const destinationBucketIndex = this.#getBucketIndex(destination.droppableId);

    // REMOVE TASK FROM SOURCE BUCKET
    const task = this.buckets.at(sourceBucketIndex).tasks.data.splice(source.index, 1).at(0);

    // ADD TASK TO DESTINATION BUCKET
    this.buckets.at(destinationBucketIndex).tasks.data.splice(destination.index, 0, task);
  }

  #mutateBucket(destination, bucket) {
    const bucketIdx = this.#getBucketIndex(bucket.id);

    // REWRITE BUCKET WITH NEW DATA FROM API
    this.buckets.splice(bucketIdx, 1, {
      ...bucket, 
      tasks: { 
        ...this.buckets.at(bucketIdx).tasks,
        data: this.buckets.at(bucketIdx).tasks.data,
      },
    });
  }

  #mutateTask(destination, task) {
    const destinationBucketIndex = this.#getBucketIndex(destination.droppableId);

    // REWRITE TASK WITH NEW DATA FROM API
    this.buckets.at(destinationBucketIndex).tasks.data.splice(destination.index, 1, task);
  }
  
  static onDragEnd = async (
    { destination, source, type, draggableId }, 
    { buckets, dataset }, 
    { toasts, toastsDispatch },
    { dashboard, searchBar }
  ) => {
    console.log({ destination, source, type, draggableId });
    if(!destination) return;

    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const dndBuckets = new DragDrop(structuredClone(buckets));
    const dndDataset = new DragDrop(structuredClone(dataset));

    switch(type) {
      case DragDrop.BUCKETS:
        // MUTATE DATASET
        dndBuckets.#moveBucket(destination, source);
        dndDataset.#moveBucket(destination, source);

        // PUSH TO STATE
        dashboard.update(dashboard.dispatch, { [dashboard.prop]: dndBuckets.buckets });
        searchBar.update(searchBar.dispatch, { [searchBar.prop]: dndDataset.buckets });

        // API CALL TO UPDATE ORDER
        var response = await handleApiCall({
          apiMethod: AxiosMethods.PATCH,
          apiRoute: ApiRoutes.BUCKET(DragDrop.#getId(draggableId)),
          callback: ({ bucket }) => {
            dndBuckets.#mutateBucket(destination, bucket);
            dndDataset.#mutateBucket(destination, bucket);
          },
          formData: {
            order_index_raw: destination.index,
          },
          toasts,
          toastsDispatch,
        });
        
        break;
      case DragDrop.TASKS:
        // MUTATE DATASET
        dndBuckets.#moveTask(destination, source);
        dndDataset.#moveTask(destination, source);

        // PUSH TO STATE
        dashboard.update(dashboard.dispatch, { [dashboard.prop]: dndBuckets.buckets });
        searchBar.update(searchBar.dispatch, { [searchBar.prop]: dndDataset.buckets });

        // API CALL TO UPDATE ORDER
        var response = await handleApiCall({
          apiMethod: AxiosMethods.PATCH,
          apiRoute: ApiRoutes.TASK(DragDrop.#getId(draggableId)),
          callback: ({ task }) => {
            dndBuckets.#mutateTask(destination, task);
            dndDataset.#mutateTask(destination, task);
          },
          formData: {
            bucket_id: dndBuckets.buckets.at(dndBuckets.#getBucketIndex(destination.droppableId)).id,
            order_index_raw: destination.index,
          },
          toasts,
          toastsDispatch,
        });
        
        break;
      default: break;
    }

    if (response?.error) dndBuckets.buckets = buckets;
    if (response?.error) dndDataset.buckets = dataset;

    return { newBuckets: dndBuckets.buckets, newDataset: dndDataset.buckets };
  }

}