import { useEffect, useState } from 'react'
import SidebarDropdown from './SidebarDropdown'
import SidebarItem from './SidebarItem'
import projectBuckets from 'data/projectBuckets'
import { stateLogout, useAuthDispatch } from 'contexts/AuthContext'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSidebarState } from 'contexts/SidebarContext'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import LogoutForm from './LogoutForm'

export default function Sidebar() {
  // CONTEXT PARAMS
  const authDispatch = useAuthDispatch();

  // CONTEXT PARAMS - SIDEBAR
  const { buckets } = useSidebarState();

  return (
    <div className="app-sidebar sidebar-text-light sidebar-shadow overflow-auto">
      <div className="scrollbar-sidebar d-flex flex-column justify-content-between">
        <nav className="app-sidebar__inner flex-fill">
          <ul className="vertical-nav-menu">

            <li className="app-sidebar__heading text-wrap text-muted">PROJECTS</li>
            <SidebarItem link="/projects/new-task" title="New Task" icon="rectangle-history-circle-plus" />
            <SidebarDropdown title="Dashboards" icon="square-kanban">
              <SidebarItem link="/projects" title="All Projects" />
              {buckets?.sort((a, b) => a.order_index > b.order_index ? 1 : -1)
                .map(bucket => <SidebarItem key={bucket.id} link={`/projects/${bucket.slug}`} title={bucket.title} />)
              }
            </SidebarDropdown>

            <SidebarItem link="/projects/archive" title="Archive" icon="box-archive" />

            <li className="app-sidebar__heading text-wrap text-muted">NOTIFICATIONS</li>
            <SidebarDropdown title="SMS Notifications" icon="message-sms">
              <SidebarItem link="/sms/history" title="History" />
              <SidebarItem link="/sms/top-up" title="Top Up" />
            </SidebarDropdown>

            <li className="app-sidebar__heading text-wrap text-muted">ACCOUNT</li>
            <SidebarItem link="/account/my-details" title="My Details" icon="id-card" />

            <li className="app-sidebar__heading text-wrap text-muted">USERS</li>
            <SidebarDropdown title="Admin Controls" icon="users-gear">
              <SidebarItem link="/user/create" title="Create New User" />
              <SidebarItem link="/user/edit" title="Update/Remove User" />
            </SidebarDropdown>

            <li className="app-sidebar__heading text-wrap text-muted">SETTINGS</li>
            <SidebarItem link="/settings/business-details" title="Business Details" icon="building" />

          </ul>
        </nav>
        <div className="menu-footer d-flex flex-row justify-content-between align-items-stretch w-100">
          <div className="w-50 d-flex flex-row justify-content-center" style={{ background: 'rgba(255,255,255,0.1)' }}>
            <span className="align-self-center text-muted">
              v.{process.env.REACT_APP_VERSION.substring(0, 6)}
            </span>
          </div>
          {/* <Button variant="dark" size="lg" className="w-50 rounded-0" onClick={() => stateLogout(authDispatch)}>
            <FontAwesomeIcon icon={['far', 'power-off']} />
          </Button> */}
          <SubmitBtnProvider>
            <LogoutForm />
          </SubmitBtnProvider>
        </div>
      </div>
    </div>
  );
}